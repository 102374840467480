export const contactInformation = {
    phoneNumberHeader: '+38 (099) 123 45 67',
    phoneNumberFooterTop: '+38 (099) 123 45 67',
    phoneNumberFooyerBottom: '+38 (099) 123 45 67',
    mail: 'skala_speckar@ukr.net',
    ceoPhoneNumber: '+38 (067)350 42  42',
    financialDepartmentPhoneNumber: '+38 (067)354 32 74',
    supplyDepartmentPhoneNumber: '+38 (067)351 83 68',
    salesDepartmentPhoneNumber: '+38 (097)999 95 52',
}
